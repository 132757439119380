<template>
  <div>
    <v-card-text>
      <v-form ref="form">
        <v-row>
          <v-card-title>
            <v-col
              cols="12"
              sm="12"
              :md="isLGAUser ? 4 : 2"
              class="pa-0"
              v-if="canSeeRegion && !isSchoolUser"
            >
              <v-autocomplete
                :label="'select Region'"
                v-model="region"
                :items="locations"
                :item-text="'name'"
                @change="fetchLGAs($event), setSelected($event)"
                prepend-icon="mdi-magnify"
                return-object
                required
                flat
                slot-scope
                clearable
                clear-icon="mdi-close-circle-outline"
                :rules="[validate.required]"
              ></v-autocomplete>
            </v-col>
            <v-col
              cols="12"
              sm="12"
              :md="isLGAUser ? 4 : 2"
              class="pa-0"
              v-if="canSeeLGA && !isSchoolUser"
            >
              <v-autocomplete
                :label="'select LGA'"
                v-model="lga"
                :items="canLoadLGA ? lgas : locations"
                :item-text="'name'"
                prepend-icon="mdi-magnify"
                return-object
                required
                @change="
                  schoolType();
                  setSelected($event);
                "
                flat
                clearable
                clear-icon="mdi-close-circle-outline"
                :rules="[validate.required]"
              ></v-autocomplete>
            </v-col>
            <v-col
              cols="12"
              sm="12"
              :md="isLGAUser ? 2 : 2"
              class="pa-0"
              v-if="(canSeeLGA || canSeeSchools) && !isSchoolUser"
            >
              <v-autocomplete
                :label="'select School Type'"
                v-model="type"
                :items="schoolTypes"
                :item-text="'name'"
                @change="fetchFacilities($event)"
                prepend-icon="mdi-magnify"
                return-object
                required
                flat
                clearable
                clear-icon="mdi-close-circle-outline"
                :rules="[validate.required]"
              ></v-autocomplete>
            </v-col>

            <v-col
              cols="12"
              sm="12"
              :md="isLGAUser ? 3 : 2"
              class="pa-0"
              v-if="(canSeeLGA || canSeeSchools) && !isSchoolUser"
            >
              <v-autocomplete
                :label="'Select Schools'"
                v-model="school"
                :items="schools"
                :item-text="'name'"
                return-object
                required
                class
                flat
                prepend-icon="mdi-magnify"
                clearable
                clear-icon="mdi-close-circle-outline"
                @change="setSelected($event), loadClasses($event)"
                :rules="[validate.required]"
              ></v-autocomplete>
            </v-col>

            <v-col cols="12" sm="12" :md="isSchoolUser ? 4 : 2" class="pa-0">
              <v-autocomplete
                :label="'Select Class'"
                v-model="formData.schoolClass"
                :items="schoolClasses"
                :item-text="'schoolClass.name'"
                return-object
                required
                class
                flat
                prepend-icon="mdi-magnify"
                clearable
                clear-icon="mdi-close-circle-outline"
                @change="loadExamsTypes()"
                :rules="[validate.required]"
              ></v-autocomplete>
            </v-col>

            <v-col cols="12" sm="12" :md="isSchoolUser ? 4 : 2" class="pa-0">
              <v-autocomplete
                :label="'Exam Type'"
                v-model="formData.examType"
                :items="examTypes"
                :item-text="'name'"
                return-object
                required
                class
                flat
                prepend-icon="mdi-magnify"
                clearable
                clear-icon="mdi-close-circle-outline"
                @change="loadAcademicYears()"
                :rules="[validate.required]"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" sm="12" :md="isSchoolUser ? 4 : 2" class="pa-0">
              <v-autocomplete
                :label="'Academic Year'"
                v-model="formData.year"
                :items="academicYears"
                :item-text="'name'"
                return-object
                required
                class
                flat
                prepend-icon="mdi-magnify"
                clearable
                clear-icon="mdi-close-circle-outline"
                :rules="[validate.required]"
                @change="loadData()"
              ></v-autocomplete>
            </v-col>
          </v-card-title>
        </v-row>
      </v-form>
    </v-card-text>
  </div>
</template>

<script>
import {
  get as fetchLocation,
  schools as fetchSchools,
  getByChildren,
} from "../locations/services";
import { get as getClasses } from "../school-classes/services";
import { get as getAcademicYears } from "../academic-years/services";

export default {
  name: "FacilityLoader",
  // props: ["selected", "setActive"],
  props: {
    setData: { type: Function, required: true },
  },

  data() {
    return {
      items: [],
      region: null,
      lga: null,
      school: null,
      type: null,
      lgas: [],
      schools: [],
      schoolTypes: [],
      schoolClasses: [],
      schoolClass: {},
      formData: {},
      examTypes: [],
      examType: {},
      academicYears: [],

      validate: {
        required: (v) => !!v || "This field required",
      },
    };
  },

  methods: {
    fetchLGAs(data) {
      getByChildren({
        "location.Id": data?.id,
      }).then((response) => {
        this.lgas = response.data[0]
          ? response.data[0].data
              .map((lga) => ({
                ...lga,
              }))
              .sort(function(a, b) {
                if (a > b) return 1;
                return -1;
              })
              .map((item, index) => ({
                ...item,
                index: ++index,
              }))
          : [];
      });
    },
    fetchFacilities(data) {
      fetchSchools({
        "lga.Id": this.lga ? this.lga?.id : this.user?.location?.id,
        "schoolType.Id": data?.id,
      }).then((response) => {
        this.schools = response.data[0]
          ? response.data[0].data
              .map((school) => ({
                ...school,
              }))
              .sort(function(a, b) {
                if (a > b) return 1;
                return -1;
              })
              .map((item, index) => ({
                ...item,
                name: item.code ? item.code + " - " + item.name : item.name,
                index: ++index,
              }))
          : [];
      });
    },

    init(params) {
      getByChildren({
        "location.Id": this.user.location ? this.user.location.id : null,
        ...params,
      }).then((response) => {
        this.items = response.data[0]
          ? response.data[0].data
              .map((lga) => ({
                ...lga,
              }))
              .sort(function(a, b) {
                if (a > b) return 1;
                return -1;
              })
              .map((item, index) => ({
                ...item,
                index: ++index,
              }))
          : [];
      });
    },

    schoolType() {
      this.$mixins.fetchByCategory("SCHOOL_TYPE").then((response) => {
        this.schoolTypes = response;
      });
    },

    setSelected(data) {
      //this.setActive(data);
      this.levelMappings(data);
    },

    loadClasses(data) {
      let params = {
        schoolId: data?.id,
      };

      getClasses(params).then((response) => {
        this.schoolClasses = response.data[0]
          ? response.data[0].data
              .map((item) => ({
                ...item,
                schoolClass: item.school_class,
              }))
              .sort(function(a, b) {
                if (a > b) return 1;
                return -1;
              })
              .map((item, index) => ({
                ...item,
                index: ++index,
              }))
          : [];
      });
    },

    loadExamsTypes() {
      this.$mixins.fetchByCategory("ALL_EXAMINATION_TYPES").then((response) => {
        this.examTypes = response;
      });
    },

    loadAcademicYears() {
      getAcademicYears().then((response) => {
        this.academicYears = response.data[0].data;
      });
    },

    levelMappings(data) {
      switch (data?.level?.id) {
        case 1:
          this.formData.country = data;
          break;
        case 2:
          this.formData.region = data;
          break;
        case 3:
          this.formData.lga = data;
          break;
        case 4:
          this.formData.ward = data;
          break;
        case 5:
          this.formData.school = data;
          break;
      }
    },

    loadData() {
      if (this.$refs.form.validate()) {
        this.setData(this.formData);
      }
      // console.log(this.formData);
    },
  },

  mounted() {
    this.init();
    if (this.isSchoolUser) {
      this.loadClasses();
    }
    //load this if use is at lga
    if (this.user && this.user.location && this.user.location.level.id === 3) {
      this.schoolType();
    }
  },

  computed: {
    user() {
      return this.$store.getters.getCurrentUser;
    },
    locations() {
      return this.items.map((item) => ({
        id: item.id,
        type: item.school_type ? item.school_type.name : "",
        name: item.name,
        level: item.level.name,
        ...item,
      }));
      // .map((item) => ({
      //   ...item,
      //   name:
      //     item.name + " " + "(" + item.type + "  " + item.level + ")" + " ",
      // }));
    },

    canSeeRegion() {
      if (
        this.user.location &&
        this.user.location.level &&
        this.user.location.level.id == 1
      ) {
        return true;
      } else {
        return false;
      }
    },

    canSeeLGA() {
      if (
        (this.user.location &&
          this.user.location.level &&
          this.user.location.level.id == 1) ||
        (this.user.location &&
          this.user.location.level &&
          this.user.location.level.id == 2)
      ) {
        return true;
      } else {
        return false;
      }
    },
    canSeeSchools() {
      if (
        (this.user.location &&
          this.user.location.level &&
          this.user.location.level.id == 1) ||
        (this.user.location &&
          this.user.location.level &&
          this.user.location.level.id == 2) ||
        (this.user.location &&
          this.user.location.level &&
          this.user.location.level.id == 3) ||
        (this.user.location &&
          this.user.location.level &&
          this.user.location.level.id == 4)
      ) {
        return true;
      } else {
        return false;
      }
    },

    canLoadLGA() {
      if (
        this.user.location &&
        this.user.location.level &&
        this.user.location.level.id == 1
      ) {
        return true;
      } else {
        return false;
      }
    },
    isLGAUser() {
      if (
        this.user &&
        this.user.location &&
        this.user.location.level.id === 3
      ) {
        return true;
      } else {
        return false;
      }
    },

    isSchoolUser() {
      if (
        this.user &&
        this.user.location &&
        this.user.location.level.id === 5
      ) {
        return true;
      } else {
        return false;
      }
    },

    academicYear() {
      return this.$mixins.academicYear();
    },
  },

  watch: {
    //on change LGA then reset schoolTypes and  and schools
    lga() {
      this.type = null;
      this.school = null;
      this.formData.schoolClass = null;
      this.formData.examType = null;
      this.formData.year = null;
    },

    schoolClass(newVal) {
      console.log(newVal);
      this.type = null;
      this.formData.examType = null;
      this.formData.year = null;
    },
  },
};
</script>

<style scoped lang="scss"></style>
